<template>
    <van-popup v-model="show" position="top">
        <div class="sellout-wrapper">
            <img class="logo" src="https://seetop-1257860468.file.myqcloud.com/vue/img/product-sellout.png">
            <div class="sellout-text">商品已下线，<a href="#" @click="viewMore">前往查看</a>更多其他商品</div>
        </div>
    </van-popup>
</template>

<script>
  import { hrefUrlCommon } from '../../utils/tools'
  export default {
    name: 'SellOutGuide',
    props: [],
    data () {
      return {
        show: false
      }
    },
    methods: {
      hrefUrlCommon,
      viewMore: function () {
        this.hrefUrlCommon('https://club.seetop.net.cn/#/home')
      },
      showPop: function () {
        this.show = true
      },
      hidePop: function () {
        this.show = false
      }
    }
  }
</script>

<style scoped>
    .sellout-wrapper {
        width: 100%;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        padding: 1rem 0 1rem 0;
    }
    .logo {
        width: 10rem;
    }
    .sellout-text {
        color: #999999;
        padding: 1rem 0;
    }
</style>
