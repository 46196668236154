<template>
    <div class="tab-wrapper">
        <div class="tab-item" v-for="items in tab" :key="items" :class="newActiveItem==items? 'item-active' : ''" @click="myClick(items)">
            <span>{{items}}</span>
            <p></p>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'TabSwitch',
    props: ['tab', 'activeItem'],
    data () {
      return {
        newActiveItem: ''
      }
    },
    methods: {
      myClick: function (items) {
        this.newActiveItem = items
        this.$emit('myClick', items)
      }
    },
    mounted () {
      this.newActiveItem = this.activeItem
    }
  }
</script>

<style scoped>
    .tab-wrapper{
        display: flex;
        display: -webkit-flex;
        /*margin-left: 4vw;*/
        padding-left: 4vw;
        background-color: #ffffff;
    }
    .tab-item{
        width:20vw;
        text-align:center;
        font-size:3.6vw;
        color:#444;
    }
    .tab-wrapper div span{
        padding:0.2rem 0.1rem
    }
    .item-active span{
        font-weight: 700;
        font-size: 3.6vw;
    }
    .item-active p{
        margin: 0.75vw 4.5vw;
        width:10vw;
        height: 0.75vw;
        border-radius: 1vw;
        background: linear-gradient(to right, #ff4949 0%,#ffaaa4 100%);
    }
</style>
