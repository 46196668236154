<!--弹窗-->
<template>
    <van-popup v-model="show">
        <div class="customer-service-wrapper">
            <img class="customer-service-qr" src="https://seetop-1257860468.file.myqcloud.com/html/img/callwx.png">
            <p class="customer-service-text">如需咨询，请添加小碗微信<br>联系电话：<a href="tel:0591-87606289">0591-87606289</a></p>
        </div>
    </van-popup>
</template>

<script>
  export default {
    name: 'CustomerService',
    data () {
      return {
        show: false
      }
    },
    methods: {
      showPop: function () {
        this.show = true
      }
    }
  }
</script>

<style scoped>
    .customer-service-wrapper{
        border-radius: 2rem;
        padding: 1.5rem 0;
        text-align: center;
        width: 20rem;
        overflow: hidden;
    }
    .customer-service-qr {
        width: 10rem;
    }
    .customer-service-text {
        color: #856d44;
        font-size: 0.9rem;
    }
</style>
