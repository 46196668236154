<!--
状态99 代表是详情页的优惠券样式
-->
<template>
    <div class="coupons-wrapper">
        <!--未使用-->
        <div class="coupons-left-wrapper" v-if="status == 0">
            <div class="coupons-price-wrapper coupons-price-img-light">
                <p>￥<span class="coupons-price">{{computedMoney}}</span></p>
                <p>满{{computedFullMoney}}元使用</p>
            </div>
            <div class="coupons-des-wrapper">
                <p>{{title}}</p>
                <p>限{{valid_end_time.split(' ')[0]}}前使用</p>
            </div>
        </div>
        <!--已使用-->
        <div class="coupons-left-wrapper" v-else-if="status == 2">
            <div class="coupons-price-wrapper coupons-price-img-dark">
                <p>￥<span class="coupons-price">{{computedMoney}}</span></p>
                <p>满{{computedFullMoney}}元使用</p>
            </div>
            <div class="coupons-des-wrapper coupons-img-use coupons-des-dark">
                <p>{{title}}</p>
                <p>限{{valid_end_time.split(' ')[0]}}前使用</p>
            </div>
        </div>
        <!--已过期-->
        <div class="coupons-left-wrapper" v-else-if="status == 3">
            <div class="coupons-price-wrapper coupons-price-img-dark">
                <p><span class="coupons-price">{{computedMoney}}</span>￥</p>
                <p>满{{computedFullMoney}}元使用</p>
            </div>
            <div class="coupons-des-wrapper coupons-img-pass-time coupons-des-dark">
                <p>{{title}}</p>
                <p>限{{valid_end_time.split(' ')[0]}}前使用</p>
            </div>
        </div>
        <!--下单时候，显示不可用-->
        <div class="coupons-left-wrapper" v-else-if="status == 4">
            <div class="coupons-price-wrapper coupons-price-img-dark">
                <p><span class="coupons-price">{{computedMoney}}</span>￥</p>
                <p>满{{computedFullMoney}}元使用</p>
            </div>
            <div class="coupons-des-wrapper coupons-des-dark">
                <p>{{title}}</p>
                <p>限{{valid_end_time.split(' ')[0]}}前使用</p>
                <p>{{reason}}</p>
            </div>
        </div>
        <!--下单的时候显示可用-->
        <div class="coupons-left-wrapper" v-if="status == 5" @click="selectCoupon">
            <div class="coupons-price-wrapper coupons-price-img-light">
                <p>￥<span class="coupons-price">{{computedMoney}}</span></p>
                <p>满{{computedFullMoney}}元使用</p>
            </div>
            <div class="coupons-des-wrapper">
                <p>{{title}}</p>
                <p>限{{valid_end_time.split(' ')[0]}}前使用</p>
            </div>
        </div>
        <!--详情页 展示领取-->
        <div class="coupons-left-wrapper" v-if="status == 99" @click="viewCouponInfo">
            <div class="coupons-price-wrapper coupons-price-img-light">
                <p>￥<span class="coupons-price">{{computedMoney}}</span></p>
                <p>满{{computedFullMoney}}元</p>
            </div>
            <div class="coupons-des-wrapper">
                <p>{{title}}</p>
                <p>限{{valid_end_time.split(' ')[0]}}前使用</p>
            </div>
        </div>
        <div class="coupons-right-wrapper" @click.stop="viewCouponInfo" v-if="status !== 99">
            <p>查</p><p>看</p><p>详</p><p>情</p>
            <img v-if="status == 0" src="https://seetop-1257860468.file.myqcloud.com/html/img/coupons_01.png">
            <img v-else src="https://seetop-1257860468.file.myqcloud.com/html/img/coupons_02.png">
        </div>
        <div class="coupons-right-wrapper" v-else-if="status == 99" @click="getCoupon">
            <p>点</p><p>击</p><p>领</p><p>取</p>
        </div>
    </div>
</template>

<script>
  import { moneyFen2Yuan, urlSetValue } from '../../utils/tools'
  import { globalConfig } from '../../utils/mixin'

  export default {
    name: 'CouponItem',
    props: ['status', 'money', 'full_money', 'title', 'valid_end_time', 'userCouponId', 'reason', 'couponId'],
    mixins: [globalConfig],
    methods: {
      // 查看优惠券详情，这边严格控制状态，只有相应的状态才可以查看
      viewCouponInfo: function () {
        if (this.status === 0 || this.status === 99 || this.status === 4 || this.status === 5) {
          this.$router.push('/coupon/' + this.couponId)
        }
      },
      // 选择优惠券，回跳原来订单页面
      selectCoupon: function () {
        const url = urlSetValue(this.$store.state.lastOrderRoute, 'userCouponId', this.userCouponId)
        this.$router.replace(url)
      },
      // 领取优惠券
      getCoupon: function () {
        const that = this
        this.$dialog.confirm({
          title: '提示',
          message: '是否领取优惠券'
        }).then(() => {
          this.axios.post(this.apiUrl + 'mall/coupon/provideCoupon', {
            couponid: that.couponId
          }).then((response) => {
            const data = response.data
            console.log(data)
            this.$toast({
              message: data.msg
            })
          })
        }).catch(() => {})
      }
    },
    computed: {
      computedMoney: function () {
        return moneyFen2Yuan(this.money)
      },
      computedFullMoney: function () {
        return moneyFen2Yuan(this.full_money)
      }
    },
    mounted () {
      console.log('当前优惠券状态值 = ' + this.status + '当前的用户优惠券id' + this.userCouponId)
      console.log(this.money)
    }
  }
</script>

<style scoped>
    .coupons-wrapper{
        width: 94vw;
        margin: 4vw auto;
        box-shadow:  0 0 4px #999;
        display: flex;
        align-items: center;
    }
    .coupons-left-wrapper{
        width: 82vw;
        display: flex;
        box-shadow:  1.5px 0 0 #ccc;
    }
    .coupons-right-wrapper{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        height: 18vw;
        padding: 2vw 0;
        justify-content: center;
    }
    .coupons-right-wrapper p {
        font-size: 3.2vw;
        width: 12vw;
        text-align: center;
        color: #666;
        margin-top: -1vw;
    }
    .coupons-des-dark p{
        color: #ccc;
    }
    .coupons-right-wrapper img{
        height: 3vw;
        width: 3vw;
        margin: 1vw 0 0 0;
    }
    .coupons-price {
        font-size: 7.5vw
    }
    .coupons-price-img-light{
        background: url("https://seetop-1257860468.file.myqcloud.com/html/img/coupons_bg1.png") no-repeat;
        background-size: 100% 100% ;
    }
    .coupons-price-img-dark{
        background: url("https://seetop-1257860468.file.myqcloud.com/html/img/coupons_bg2.png") no-repeat;
        background-size: 100% 100% ;
    }
    .coupons-img-use{
        background: url("https://seetop-1257860468.file.myqcloud.com/html/img/coupons_sy.png") no-repeat 40vw 0;
        background-size: 15vw;
    }
    .coupons-img-pass-time{
        background: url("https://seetop-1257860468.file.myqcloud.com/html/img/coupons_gq.png") no-repeat 40vw 0;
        background-size: 15vw;
    }
    .coupons-price-wrapper{
        width: 30vw;
        height: 25vw;
        color: #FFFFff;
        font-size: 3.2vw;
    }
    .coupons-price-wrapper p{
        width: 30vw;
        text-align: center;
    }
    .coupons-price-wrapper p:nth-child(1){
        font-weight: 800;
        margin-top: 4vw;
    }
    .coupons-des-wrapper{
        width: 52vw;
        height: 25vw;
        font-size: 3.2vw;
    }
    .coupons-des-wrapper p{
        width: 52vw;
        text-align: center;
    }
    .coupons-des-wrapper p:nth-child(1){
        font-size: 4.2vw;
        margin-top: 6.4vw;
    }
    .coupons-des-wrapper img {
        width: 5vw;
        height: 5vw;
        margin:1vw 0 0 50vw;
    }
</style>
