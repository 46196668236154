<template>
    <van-overlay :show="show" @click="show = false">
        <img class="share-image" src="https://seetop-1257860468.file.myqcloud.com/html/distributor/wx_20210406161925.png">
    </van-overlay>
</template>

<script>
  export default {
    name: 'SharePop',
    data () {
      return {
        show: false
      }
    },
    methods: {
      showPop: function () {
        this.show = true
      }
    }
  }
</script>

<style scoped>
    .share-image {
        width: 80%;
        margin: 5rem auto 0;
        display: block;
    }
</style>
