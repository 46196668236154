<template>
    <div class="body">
        <!--banner-->
        <van-swipe class="swipe-wrapper" :autoplay="3000" indicator-color="white">
            <van-swipe-item v-for="(item,index) in banner" :key="index">
                <img class="swipe-img" :src="'https://seetop-1257860468.file.myqcloud.com/seetop/uploads/' + item">
            </van-swipe-item>
        </van-swipe>
        <!--售卖进度条-->
        <div class="progress-wrapper" v-if="isProgress == 1">
            <div class="progress-text">已抢{{percentage}}%：</div>
            <van-progress class="progress-line" pivot-text="" :percentage="percentage" color="#ee0a24"/>
            <div class="progress-number">已售：{{saleNumber}}份</div>
        </div>
        <!--商品名-->
        <div class="product-name">{{productName}}</div>
        <!--价格-->
        <div class="product-price-wrapper">
            <div>￥{{salePrice}}</div>
            <div>原价￥{{orgPrice}}</div>
        </div>
        <!--分销操作，赚多少、分享、获取海报-->
        <div class="distribution-wrapper" v-if="isDistributionMember == 1">
            <div class="distribution-earn-wrapper">
                <div>赚</div>
                <div>{{distributionMoney}}</div>
            </div>
            <div class="distribution-share-wrapper" @click="viewShare">
                <div class="distribution-share-item">
                    <img src="https://seetop-1257860468.file.myqcloud.com/vue/img/20210427%E5%BE%AE%E4%BF%A1.svg">
                    <div>发送好友</div>
                </div>
                <div class="distribution-share-item" @click="viewPoster">
                    <img src="https://seetop-1257860468.file.myqcloud.com/vue/img/20210427%E4%B8%8B%E8%BD%BD.svg">
                    <div>获取海报</div>
                </div>
            </div>
        </div>
        <share-pop ref="share"></share-pop>
        <!--优惠券展示位-->
        <div class="coupon-wrapper" v-if="couponAdList">
            <coupon-item v-for="item,index in couponAdList" :key="index" style="margin: 1rem 0 0 0;"
                         :status="99"
                         :money="item.money"
                         :full_money="item.full_money"
                         :title="item.title"
                         :valid_end_time="item.valid_end_time"
                         :couponId="item.id"
            ></coupon-item>
        </div>
        <!--广告展示位-->
        <div class="ad-wrapper">
            <img v-if="adImg" :src="'https://seetop-1257860468.file.myqcloud.com/seetop/uploads/' + adImg" class="ad-img" @click="clickAd">
        </div>
        <!--图文详情、购买须知-->
        <tab-switch class="tab" :tab="tabName" activeItem="图文详情" v-on:myClick="tab"></tab-switch>
        <div class="detail-text-wrapper" v-html="graphicDetail" v-if="currentTab == '图文详情'"></div>
        <div class="detail-remark-wrapper" v-if="currentTab == '购买须知'">
            <div v-for="item,index in remark" :key="index">{{item}}</div>
        </div>
        <!--引导关注-->
        <van-overlay v-if="isSubscribe == 0" :show="!isSubscribe">
            <div class="subscribe-wrapper">
                <img :src="'https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=' + subscribeTicket">
                <p>为了及时查收消息，请长按扫描关注公众号</p>
            </div>
        </van-overlay>
        <!--底栏-->
        <buy-product-bar-v2 v-if="propsSubProductList"
                            :subProduct="propsSubProductList"
                            :productSku="productSku"
                            :apiTimestamp="apiTimestamp"
                            :productName="productName"
                            :cellId="currentCellId"
                            :isBind="isBindUser"
                            :businessId="businessId"
                            :express="express"
                            :product-img="banner[0]"
        >
        </buy-product-bar-v2>
    </div>
</template>

<script>
  import TabSwitch from '../../components/common/TabSwitch'
  import { globalConfig, WXJsdk } from '../../utils/mixin'
  import {
    getBetweenNumber,
    getChannelParams,
    getPostSrcChannel, getUrlParams,
    hrefUrlCommon,
    moneyFen2Yuan,
    setShopTitle
  } from '../../utils/tools'
  import SharePop from '../../components/common/SharePop'
  import { getCookie } from '../../utils/cookie'
  import CouponItem from '../../components/order/CouponItem'
  import BuyProductBarV2 from '../../components/product/BuyProductBarV2'
  export default {
    name: 'Product',
    components: { BuyProductBarV2, CouponItem, SharePop, TabSwitch },
    mixins: [globalConfig, WXJsdk],
    data: function () {
      return {
        tabName: ['图文详情', '购买须知'],
        currentTab: '图文详情',
        isDistributionMember: '',
        banner: [],
        currentCellId: '',
        businessId: '',
        posterId: '',
        productName: '',
        salePrice: '',
        orgPrice: '',
        distributionMoney: '',
        graphicDetail: '',
        remark: '',
        propsSubProductList: '',
        productSku: [],
        couponAdList: [],
        apiTimestamp: '',
        // 引导关注二维码
        subscribeTicket: '',
        // 是否关注
        isSubscribe: 1,
        // 是否绑定
        isBindUser: true,
        percentage: 20,
        saleNumber: 0,
        isProgress: 0,
        adImg: '', // 广告图片
        adUrl: '', // 广告链接
        express: 0
      }
    },
    methods: {
      moneyFen2Yuan,
      hrefUrlCommon,
      init: function () {
        const that = this
        //  请求
        this.axios.post(this.apiUrl + 'mall/init/detailsV4', {
          cellId: this.currentCellId,
          srcChannel: getPostSrcChannel(),
          urlChannel: getUrlParams('channel') // 传到后台记录，原始的点击
        }).then((response) => {
          const data = response.data
          const result = data.data
          // 基本信息获取
          that.productName = result.cellProductInfo.name
          that.businessId = result.cellProductInfo.businessid
          that.distributionMoney = result.cellProductInfo.distributionMoney
          that.graphicDetail = result.cellProductInfo.details
          that.banner = result.cellProductInfo.images
          that.remark = result.cellProductInfo.remark
          that.propsSubProductList = result.subProductList
          that.productSku = result.cellProductInfo.specs_list
          that.apiTimestamp = result.timestamp
          that.subscribeTicket = result.cellProductInfo.subscribe_img.ticket
          that.isSubscribe = result.isSubscribe
          that.couponAdList = result.cellProductInfo.coupon_ad
          that.express = result.cellProductInfo.express
          // console.log('11111111111111', that.express)
          that.percentage = result.percentage
          that.saleNumber = result.saleNumber
          that.isProgress = result.isProgress
          that.adImg = result.cellProductInfo.ad_img
          that.adUrl = result.cellProductInfo.ad_url
          console.log('关注参数' + that.isSubscribe)
          console.log('关注qr' + that.subscribeTicket)
          that.isBindUser = result.isBindUser
          // 单独处理价格，让数组变成区间
          let salePriceArr = []
          let prgPriceArr = []
          for (const i in result.subProductList) {
            salePriceArr.push(result.subProductList[i].mallprice)
            prgPriceArr.push(result.subProductList[i].price)
            that.posterId = i
          }
          salePriceArr = getBetweenNumber(salePriceArr)
          prgPriceArr = getBetweenNumber(prgPriceArr)
          if (salePriceArr[0] === salePriceArr[1]) {
            that.salePrice = moneyFen2Yuan(salePriceArr[0])
          } else {
            that.salePrice = moneyFen2Yuan(salePriceArr[0]) + '~' + moneyFen2Yuan(salePriceArr[1])
          }
          that.orgPrice = moneyFen2Yuan(prgPriceArr[1])
          // 处理分享 (微信的分享？)
          that.WxShareConfig(result.shareInfo.title, result.shareInfo.desc, that.createDistributionShareUrl(window.location.href), 'https://seetop-1257860468.file.myqcloud.com/seetop/uploads/' + result.shareInfo.imgUrl)
          // 设置当前店铺名，只有一种可能；用户不是分销员，并且当前渠道存在有效；
          if (that.isDistributionMember == 0 && result.srcChannelShopName) {
            setShopTitle(result.srcChannelShopName)
          }
        })
      },
      tab: function (name) {
        this.currentTab = name
      },
      viewPoster: function () {
        this.$router.push('/salePoster?goodsId=' + this.posterId)
      },
      viewShare: function () {
        this.$refs.share.showPop()
      },
      clickAd: function () {
        if (this.adUrl) {
          this.hrefUrlCommon(this.adUrl)
        }
      }
    },
    computed: {},
    created () {
      this.currentCellId = this.$route.params.id
      getChannelParams()
      // 浏览器判断
      this.isDistributionMember = getCookie('isDistributionMember')
      this.init()
    }
  }
</script>

<style scoped>
    .body {
        min-height: 100%;
        min-width: 100%;
        background: #f2f2f2;
    }
    .swipe-wrapper {
        width: 100%;
    }
    .swipe-img {
        width: 100%;
    }
    .progress-wrapper {
        background-color: #ffffff;
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: space-around;
        padding: 0.7rem 0 0;
    }
    .progress-text {
        color: #fa1f1f;
        font-size: 0.9rem;
    }
    .progress-number {
        color: #7c7c7c;
        font-size: 0.9rem;
    }
    .progress-line {
        width: 50%;
    }
    .product-name {
        font-weight: 600;
        font-size: 1.1rem;
        box-sizing: border-box;
        padding: 1rem;
        background-color: #ffffff;
    }
    .product-price-wrapper {
        display: flex;
        flex-flow: row;
        padding: 0 1rem 0.5rem;
        align-items: flex-end;
        background-color: #ffffff;
    }
    .product-price-wrapper > div:first-child {
        color: #fa1f1f;
        font-size: 1.2rem;
        font-weight: 600;
    }
    .product-price-wrapper div:nth-child(2) {
        color: #92909f;
        font-size: 0.9rem;
        text-decoration: line-through;
        margin-left: 1rem;
    }
    .distribution-wrapper {
        padding: 0.2rem 1rem 0.4rem;
        background-color: #ffffff;
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        align-items: center;
    }
    .distribution-earn-wrapper {
        background-color: #FAF1F1;
        display: flex;
        flex-flow: row;
        border-radius: 10rem;
    }
    .distribution-earn-wrapper > div:first-child {
        font-size: 0.9rem;
        line-height: 1.5rem;
        width: 1.5rem;
        height: 1.5rem;
        text-align: center;
        border-radius: 10rem;
        background-color: #F66262;
        color: #ffffff;
    }
    .distribution-earn-wrapper > div:nth-child(2) {
        color: #FF504C;
        margin: 0 0.8rem;
    }
    .distribution-share-wrapper > div:first-child {
        /*border-right: 0.1rem solid #c3c3c3;*/
    }
    .distribution-share-wrapper {
        display: flex;
        flex-flow: row;
    }
    .distribution-share-item {
        text-align: center;
        padding: 0 0.5rem;
    }
    .distribution-share-item:first-child {
        border-right: 0.1rem solid #c3c3c3;
        padding-right: 1rem;
    }
    .distribution-share-item:nth-child(2) {
        padding-left: 1rem;
    }
    .distribution-share-item img {
        width: 1.4rem;
    }
    .distribution-share-item div {
        font-size: 0.7rem;
        text-align: center;
        color: #7c7c7c;
        /*margin-top: 0.2rem;*/
    }
    .tab {
        margin-top: 0.7rem;
        padding: 0.5rem 1rem;
    }
    .detail-text-wrapper {
        width: 100%;
        background-color: #ffffff;
        overflow: hidden;
    }
    .detail-text-wrapper >>> img {
        width: 100%;
    }
    .detail-remark-wrapper {
        width: 100%;
        background-color: #ffffff;
        padding: 1rem;
        box-sizing: border-box;
        font-size: 0.9rem;
        color: #505050;
    }
    .subscribe-wrapper {
        width: 80%;
        background-color: #ffffff;
        margin: 5rem auto;
        text-align: center;
        padding: 1rem 0;
    }
    .subscribe-wrapper img {
        width: 90%;
        margin: 0 auto;
    }
    .subscribe-wrapper p {
        font-size: 1rem;
    }
    .coupon-wrapper {
        width: 100%;
        display: flex;
        flex-flow: column;
        align-items: center;
        background-color: #ffffff;
        margin-top: 0.5rem;
        padding-bottom: 1rem;
    }
    .ad-wrapper {
        margin-top: 0.4rem;
        width: 100%;
    }
    .ad-img {
        width: 98%;
        margin: 0 auto;
        display: block;
    }
</style>
