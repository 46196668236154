<!--
规格面板
-->
<template>
    <van-action-sheet v-model="showSpecsPanel">
        <div class="content">
            <div class="specs-name">{{productName}}</div>
            <!--多规格才出现，辅助选择规格的提示-->
            <div v-if="specsCount > 1">
                <div class="specs-product-tip" v-if="currentSelectProductId == ''">您还未选择商品</div>
                <div class="specs-product-tip" v-else>已选：{{currentSelectProductSkuText}}</div>
            </div>
            <div class="specs-price-wrapper" v-if="currentSelectProductId">
                <div>￥{{selectAllProductPrice}}</div>
                <div>￥{{selectAllProductOrgPrice}}</div>
            </div>
            <div class="sku-wrapper" v-if="(specsCount > 1) && (allSukComboMap.size > 0)">
                <div class="sku-item-wrapper" v-for="item,key in productSku" :key="key">
                    <div class="sku-name">{{key}}</div>
                    <div class="sku-box">
                        <span v-for="item2,key2 in item" :key="key2" @click="clickSpec(key,item2)"
                              :class="[item2 == userSelectSpecMap.get(key) ? 'sku-item-active' : '', predictCanChoice(key,item2) ? '' : 'sku-default']"
                              :ref="`${key}_${item2}`">{{item2}}</span>
                    </div>
                </div>
            </div>
            <div class="specs-number-wrapper">
                <div>数量</div>
                <div>
                    <van-stepper v-model="productNumber" integer disable-input :max="limitBuyNumber"/>
                </div>
            </div>
            <div class="specs-button" @click="submitOrder">确定</div>
        </div>
    </van-action-sheet>
</template>

<script>
  import { moneyFen2Yuan, standardTimeIsOver } from '../../utils/tools'
  import { appendShopCart, buyNow } from '../../utils/cart'

  export default {
    name: 'SpecsPanel',
    props: ['productName', 'specsCount', 'subProduct', 'productSku', 'businessId', 'saleProduct', 'productImg'],
    data () {
      return {
        showSpecsPanel: false,
        currentSelectProductId: '', // 当前选择的商品Id
        currentSelectProductSkuText: '', // 当前选择的商品描述
        currentSelectProductPrice: 0,
        currentSelectProductOrgPrice: 0,
        subProductMap: new Map(), // props 传入的subProduc数组处理为Map，【关键参数】
        userSelectSpecMap: new Map(), // 记录用户选择的规格组合，例如有3种属性，用户选择了蓝色、8寸、11条 => ['蓝色','8寸','11条']
        allSukComboMap: new Map(), // 所有规格组合情况，【关键参数】
        limitBuyNumber: 9, // 限制购买数量，默认是9
        productNumber: 0, // 选择的商品数量
        intention: 'order' // 当前展示面板的目的，直接购买还是添加购物车 order addCart
      }
    },
    methods: {
      /**
       * 数据初始化， 主函数
       */
      dataInit: function () {
        // 1. 商品数组由key = 0 1 2 3的对象转换成key = 白色|64G|移动的Map
        this.subProductObj2Map()
        // 2. 初始化用户选择规格情况，规格全部默认未选择为空
        for (const i in this.productSku) {
          this.userSelectSpecMap.set(i, '')
        }
        // 3. 获取所有规格组合情况
        const allSukComboMap = this.getSepcCommboStatus()
        console.log('所有规格组合情况 -------------------- 》》》 ', allSukComboMap)
        this.allSukComboMap = allSukComboMap
        // 4. 强制刷新视图
        this.$forceUpdate()
      },
      /**
       * 【关键函数】 主要功能SubProduct从对象转成Map  key = 白色|64G|移动，value为原数据的Map对象
       * 传入：对象
       * 返回：Map
       */
      subProductObj2Map: function () {
        const subProductMap = new Map()
        // 1. 循环组件传入的商品对象
        for (const i in this.subProduct) {
          const val = this.subProduct[i]
          // 2. 判断，还没上线的商品可以显示
          if (standardTimeIsOver(val.online_start)) {
          } else {
            continue
          }
          // 3. 判断，已经过期的商品不再显示
          if (standardTimeIsOver(val.online_end)) {
            continue
          }
          // 4. 判断，没上线的商品不显示
          if (val.status === 0) {
            continue
          }
          const mapKey = this.specObject2MapKey(new Map(Object.entries(val.specs)))
          // 5. 通过筛选的商品，重新构造成Map
          subProductMap.set(mapKey, val)
        }
        this.subProductMap = subProductMap
      },
      /**
       * 获取任一规格组合下，每个属性允许亮起的情况
       */
      getSepcCommboStatus: function () {
        // 1. 获取规格属性数量
        const skuNumber = Object.keys(this.productSku).length
        // 2. 获取含有空字符串的笛卡尔积数组，空字符串代表该属性没有选择；['红色','','11寸']代表只选择了红色和11寸，没有选择重量
        const dikaerArrar = this.pushNullSpec2DikaerArray()
        const allSukComboMap = new Map()
        // 3.循环包含所有情况的笛卡尔积
        dikaerArrar.forEach((val) => {
          const sukComboMap = new Map() // 满足条件且有库存
          const currentMapKey = this.specObject2MapKey(val) // ["白色", "7寸", "9朵"] => 9朵|7寸|白色|
          // 4. 优化，当前规格组合不存在空字符串，直接去获取这个组合的数据
          if (val.indexOf('') === -1) {
            this.createSukComboMap(sukComboMap, val)
            allSukComboMap.set(currentMapKey, sukComboMap)
            return
          }
          // 5. 循环找到符合条件的所有组合
          dikaerArrar.forEach((val2) => {
            if (val2.indexOf('') === -1) {
            } else {
              // 6. 有空字符串的不满足条件
              return
            }
            for (let i = 0; i < skuNumber; i++) {
              if (val[i] === '') {
              } else {
                if (val[i] === val2[i]) {
                } else {
                  return
                }
              }
            }
            // 7. 收集结果
            this.createSukComboMap(sukComboMap, val2)
          })
          // 8. 收集到的结果，在循环结束的时候放到总Map
          allSukComboMap.set(currentMapKey, sukComboMap)
        })
        return allSukComboMap
      },
      /**
       * 利用了引用类型的特性 把sku数组依次push加入对应key的combomap中， key => combomap
       * 输入：引用类型 、 规格数组
       * 返回：无返回，直接操作引用类型
       */
      createSukComboMap: function (sukComboMap, arr) {
        // 1. 规格key转换
        const key = this.specObject2MapKey(arr)
        let currentStock = 0
        // 2. 判断规格数据是否存在
        if (this.subProductMap.get(key)) {
          currentStock = this.subProductMap.get(key).stock
        }
        // 3. 如果数据存在，并且还没售罄则进行操作
        if (currentStock > 0) {
          arr.forEach((val3, index3) => {
            const aldArr = sukComboMap.get(index3)
            let singleSukCombo = []
            if (aldArr) {
              singleSukCombo = aldArr
            }
            // 4. 把所有可以操作的情况保存起来，可能出现重复的情况，但是不影响计算
            singleSukCombo.push(val3)
            sukComboMap.set(index3, singleSukCombo)
          })
        }
      },
      /**
       * 点击任一规格，对数据进行计算、验证和重新渲染
       */
      clickSpec: function (key, sku) {
        // 1. 获取节点
        const _ref = `${key}_${sku}`
        const target = this.$refs[_ref]
        // 2. 判断节点情况，已点击和已售罄都不执行后续代码
        if ((target[0].className === 'sku-default') || (target[0].className === 'sku-item-active')) {
          return
        }
        // 3. 把当前点击规格放到已选择数组中
        this.userSelectSpecMap.set(key, sku)
        // 4. 判断是否规格已经选择完毕
        let selectFinish = 1
        this.userSelectSpecMap.forEach((val) => {
          if (val === '') {
            selectFinish = 0
            return 0
          }
        })
        if (selectFinish === 1) {
          // 5. 如果所有属性都选择了
          const key = this.specObject2MapKey(this.userSelectSpecMap)
          const product = this.subProductMap.get(key)
          this.currentSelectProductId = product.id
          this.currentSelectProductSkuText = key.replaceAll('|', ' ')
          this.currentSelectProductPrice = product.mallprice
          this.currentSelectProductOrgPrice = product.price
          this.limitBuyNumber = product.buylimit
        } else {
          // 6. 如果有的属性还没选择
          this.currentSelectProductId = ''
          this.currentSelectProductSkuText = ''
          this.currentSelectProductPrice = 0
          this.currentSelectProductOrgPrice = 0
          this.limitBuyNumber = 9
        }
        // 7. 刷新视图
        this.$forceUpdate()
      },
      /**
       * 预判规格状态
       * 对规格属性进行预判：是否可以点击
       */
      predictCanChoice: function (index, sku) {
        const judgeMap = new Map()
        // 1. 拿到当前规格选择情况
        this.userSelectSpecMap.forEach((val, index) => {
          judgeMap.set(index, val)
        })
        // 2. 把自身放入选择中进行预判
        judgeMap.set(index, sku)
        const judgeKey = this.specObject2MapKey(judgeMap)
        // 3. 获取预判数据
        const s = this.allSukComboMap.get(judgeKey)
        // 4. 如果有数据则预判为可以点击
        if (s.size > 0) {
          return true
        } else {
          return false
        }
      },
      // 选好规格和数量，提交
      submitOrder: function () {
        if (this.currentSelectProductId) {
          if (this.intention === 'order') {
            buyNow(this.businessId, this.currentSelectProductId, this.productNumber)
            this.$router.push('/goodsOrder')
          } else {
            // 加入购物车
            appendShopCart(
              this.businessId,
              this.currentSelectProductId,
              this.productNumber, this.productName,
              this.currentSelectProductPrice,
              this.currentSelectProductOrgPrice,
              this.currentSelectProductSkuText,
              this.productImg,
              this.limitBuyNumber
            )
            this.$dialog.confirm({
              title: '提示',
              message: '添加成功，前往购物车'
            }).then(() => {
              this.$router.push('/cart')
            })
          }
        } else {
          this.$toast('请先选择商品和规格')
        }
      },
      /**
       * 把规格数组/对象 转换为对应字符串
       * 输入：[白色,64G,'']
       * 输出：白色|64G||
       */
      specObject2MapKey: function (map) {
        let key = ''
        map.forEach((item) => {
          // key = item + '|' + key
          key = key + '|' + item
        })
        key = key.substr(1)
        return key
      },
      /**
       * 为每一个属性添加空值，并生成笛卡尔积，为了模拟某一个规格没选择的情况
       * 返回的是一个二维数组
       * @returns {Array|*}
       */
      pushNullSpec2DikaerArray: function () {
        var arr = []
        const descartesArr = []
        const productSkuMap = new Map(Object.entries(this.productSku))
        // 如果是单规格商品，执行下面的代码
        if (productSkuMap.size === 1) {
          const b = productSkuMap.get(Object.keys(this.productSku)[0])
          b.forEach((val) => {
            const c = []
            c.push(val)
            descartesArr.push(c)
          })
          descartesArr.push([''])
          return descartesArr
        }
        productSkuMap.forEach((val) => {
          arr = [...val]
          arr.push('')
          descartesArr.push(arr)
        })
        const dikaer = this.descartes(descartesArr)
        return dikaer
      },
      /**
       * 标准算法 笛卡尔积
       * @param args
       * @returns {*|Array|*|(function(*, *): Array)}
       */
      descartes: function (args) {
        if (args.length < 2) {
          return args[0] || []
        }
        return [].reduce.call(args, (col, set) => {
          const res = []
          col.forEach(c => {
            set.forEach(s => {
              const t = [].concat(Array.isArray(c) ? c : [c])
              t.push(s)
              res.push(t)
            })
          })
          return res
        })
      },
      openSpecsPanel: function (intention) {
        this.showSpecsPanel = true
        this.intention = intention
      }
    },
    computed: {
      // 计算商品价格 单品价 * 选择的数量
      selectAllProductPrice: function () {
        const price = moneyFen2Yuan(this.productNumber * this.currentSelectProductPrice)
        return price
      },
      // 计算商品原价格 单品原价 * 选择的数量
      selectAllProductOrgPrice: function () {
        const price = moneyFen2Yuan(this.productNumber * this.currentSelectProductOrgPrice)
        return price
      }
    },
    mounted () {
      // 如果是单规格商品，也就是specsCount为1的时候，用户是不需要去选择规格的，直接帮他选择【第一个】
      if (this.specsCount === 1) {
        this.currentSelectProductId = this.saleProduct.length ? this.saleProduct[0].id : 0
        this.limitBuyNumber = this.saleProduct.length ? this.saleProduct[0].buylimit : 9
        this.currentSelectProductPrice = this.saleProduct.length ? this.saleProduct[0].mallprice : 0
        this.currentSelectProductOrgPrice = this.saleProduct.length ? this.saleProduct[0].price : 0
      } else {
        this.dataInit()
      }
    }
  }
</script>

<style scoped>
    .specs-name {
        font-size: 1.1rem;
        font-weight: 600;
        margin-top: 1rem;
        padding: 0 1rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
    }

    .specs-product-tip {
        font-size: 0.9rem;
        color: #7c7c7c;
        padding: 1rem;
    }

    .specs-price-wrapper {
        display: flex;
        flex-flow: row;
        align-items: flex-end;
        padding: 0 1rem 1rem;
    }

    .specs-price-wrapper > div:first-child {
        font-size: 1.1rem;
        color: #fa1f1f;
    }

    .specs-price-wrapper > div:nth-child(2) {
        font-size: 0.9rem;
        color: #7c7c7c;
        text-decoration: line-through;
        margin-left: 0.5rem;
    }

    .specs-number-wrapper {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        align-items: center;
        padding: 0 1rem 1rem;
    }

    .specs-button {
        color: #ffffff;
        background-color: #F66262;
        text-align: center;
        padding: 0.8rem 0;
        font-size: 1.2rem;
    }

    .sku-wrapper {
        display: flex;
        flex-flow: column;
        width: 93%;
        margin: 0 auto;
    }

    .sku-item-wrapper {
        margin-bottom: 1rem;
    }

    .sku-name {
        color: #333333;
    }

    .sku-box {
        display: flex;
        flex-flow: wrap;
    }

    .sku-box span {
        margin: 0.5rem 1rem 0 0;
        background-color: #e5e5e5;
        color: #333333;
        padding: 0.5rem 1rem;
        font-size: 0.9rem;
        box-sizing: border-box;
    }

    .sku-item-active {
        background-color: #ffc9c9 !important;
        color: #ee0a24 !important;
    }

    .sku-default {
        text-decoration: line-through;
    }
</style>
