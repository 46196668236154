<!--把规格列表传入进来，这边专注处理购买商品栏-->
<template>
    <div>
        <div style="width: 100%;height: 5rem;"></div>
        <share-pop ref="share"></share-pop>
        <customer-service ref="service"></customer-service>
        <!--购买栏-->
        <div class="product-bar-wrapper">
            <!--当前规格没有在卖的，并且最少有一个待开售，则显示-->
            <div class="product-count-down" v-if="subSaleArr.length == 0 && subRemindArr.length >0">
                距离开始还有：
                <van-count-down :time="countDown" format="DD 天 HH 时 mm 分 ss 秒" class="vant-count-down"/>
            </div>
            <div class="product-bar">
                <div class="product-convenient-wrapper">
                    <div class="product-convenient-item" @click="toHome">
                        <img src="https://seetop-1257860468.file.myqcloud.com/vue/img/20210427%E9%A6%96%E9%A1%B5.svg">
                        <p>首页</p>
                    </div>
                    <div class="product-convenient-item" @click="viewShare">
                        <img src="https://seetop-1257860468.file.myqcloud.com/vue/img/20210427%E5%88%86%E4%BA%AB.svg">
                        <p>分享</p>
                    </div>
                    <div class="product-convenient-item" @click="viewCustomerService">
                        <img src="https://seetop-1257860468.file.myqcloud.com/vue/img/20210427%E5%AE%A2%E6%9C%8D.svg">
                        <p>客服</p>
                    </div>
                </div>
                <div class="product-button-wrapper">
                    <div class="product-button-large" style="background-color: rgb(246,189,76)"
                         @click="viewSpecsPanel('addCart')" v-show="(express == 1) && (subSaleArr.length > 0)">加入购物车
                    </div>
                    <!--如果规格里面有在卖的，那么现实立即购买-->
                    <div class="product-button-large" v-if="subSaleArr.length > 0" @click="viewSpecsPanel('order')">立即购买</div>
                    <!--判断是否有待开售的，如果有就显示-->
                    <div class="product-button-large" style="background-color: #3cc51f;"
                         v-else-if="subRemindArr.length >0" @click="setRemind">开售提醒
                    </div>
                    <!--规格全部都已结束才显示已结束-->
                    <div class="product-button-large" style="background-color: #9c9c9c;"
                         v-else-if="subFinishArr.length == specsCount">已结束
                    </div>
                    <!--规格全部都已售罄才显示已售罄-->
                    <div class="product-button-large" style="background-color: #9c9c9c;"
                         v-else-if="subSellOutArr.length == specsCount && specsCount != 0">售罄
                    </div>
                </div>
            </div>
        </div>
        <specs-panel
                v-if="initFinish"
                ref="specsPanel"
                :product-name="productName"
                :specs-count="specsCount"
                :sub-product="subProduct"
                :product-sku="productSku"
                :business-id="businessId"
                :sale-product="subSaleArr"
                :product-img="productImg"
        ></specs-panel>
        <!--售罄、已下线弹窗-->
        <sell-out-guide ref="sellOutPop"></sell-out-guide>
    </div>
</template>

<script>
  import SharePop from '../common/SharePop'
  import CustomerService from '../common/CustomerService'
  import { getCurrentTimestamp, standardTime2Timestamp } from '../../utils/tools'
  import { globalConfig } from '../../utils/mixin'
  import SellOutGuide from './SellOutGuide'
  import SpecsPanel from './SpecsPanel'

  export default {
    name: 'BuyProductBarV2',
    components: {
      SpecsPanel,
      SellOutGuide,
      CustomerService,
      SharePop
    },
    props: ['subProduct', 'productSku', 'apiTimestamp', 'productName', 'cellId', 'isBind', 'businessId', 'express', 'productImg'],
    mixins: [globalConfig],
    data () {
      return {
        // 规格总数，计算了所有status不等于0的规格，最少都是1个
        specsCount: 0,
        // 规格里 还没开售的
        subRemindArr: [],
        // 规格里 正在卖的
        subSaleArr: [],
        // 规格里 已结束的
        subFinishArr: [],
        // 规格里 已经售罄的
        subSellOutArr: [],
        // 规格里 没有上线的
        subBePending: [],
        initFinish: false
      }
    },
    methods: {
      standardTime2Timestamp,
      // 分享遮罩
      viewShare: function () {
        this.$refs.share.showPop()
      },
      // 跳转首页
      toHome: function () {
        this.$router.push('/home')
      },
      // 客服遮罩
      viewCustomerService: function () {
        this.$refs.service.showPop()
      },
      // 规格面板
      viewSpecsPanel: function (intention) {
        const that = this
        if (that.isBind === 1) {
        } else {
          this.$toast({
            message: '请先前往绑定',
            onClose: function () {
              that.$router.push('/bind?url=' + window.location.href)
            }
          })
          return
        }
        this.$refs.specsPanel.openSpecsPanel(intention)
      },
      // 设置开售提醒
      setRemind: function () {
        const that = this
        if (that.isBind === 1) {
        } else {
          this.$toast({
            message: '请先前往绑定',
            onClose: function () {
              that.$router.push('/bind?url=' + window.location.href)
            }
          })
          return
        }
        this.axios.post(this.apiUrl + 'mall/remind/remind', {
          type: 'product',
          gid: this.cellId,
          url: window.location.href
        }).then((response) => {
          const data = response.data
          that.$toast(data.msg)
        })
      },
      // 判断是否弹出售罄遮罩
      initSellOutPop: function () {
        if ((this.subFinishArr.length === this.specsCount) || (this.subSellOutArr.length === this.specsCount)) {
          console.log('要弹出来！')
          this.$refs.sellOutPop.showPop()
        } else {
          console.log('不弹出来！')
        }
      },
      // 初始化商品数组，设置好各个数组
      initProductArr: function () {
        for (const i in this.subProduct) {
          // 获取参数
          const mOnlineStart = this.subProduct[i].online_start
          const mOnlineEnd = this.subProduct[i].online_end
          const mStatus = this.subProduct[i].status
          const mLastStock = this.subProduct[i].lastStock
          // 至关重要的分类，对所有规格商品进行分类，判断其状态
          // status和laststock判断优先级最高，接下去才是判断时间
          if (mStatus === 0) {
            // 没上线，判断优先级最高
            this.subBePending.push(this.subProduct[i])
          } else if (mLastStock <= 0) {
            // 售罄，优先级第二
            this.subSellOutArr.push(this.subProduct[i])
            this.specsCount++
          } else if (this.currentTimestamp < this.standardTime2Timestamp(mOnlineStart)) {
            // 即将上线
            this.subRemindArr.push(this.subProduct[i])
            this.specsCount++
          } else if (this.currentTimestamp > this.standardTime2Timestamp(mOnlineEnd)) {
            // 已结束
            this.subFinishArr.push(this.subProduct[i])
            this.specsCount++
          } else {
            // 正在卖
            this.subSaleArr.push(this.subProduct[i])
            this.specsCount++
          }
        }
        console.log(this.specsCount)
        // console.log(this.subSaleArr)
        // console.log(this.subRemindArr)
        // console.log(this.subFinishArr)
        // console.log(this.subSellOutArr)
        this.initFinish = true
      }
    },
    computed: {
      // 开售倒计时，实际上是第一个商品的倒计时
      countDown: function () {
        const startTimestamp = standardTime2Timestamp(this.subRemindArr[0].online_start)
        return (startTimestamp - this.apiTimestamp) * 1000
      },
      currentTimestamp: function () {
        return getCurrentTimestamp()
      }
    },
    mounted () {
      this.initProductArr()
      this.initSellOutPop()
      // console.log(this.productImg)
    }
  }
</script>

<style scoped>
    .product-bar-wrapper {
        box-sizing: border-box;
        width: 100%;
        position: fixed;
        left: 0;
        bottom: 0;
        border-top: 0.1rem solid #e6e6e6;
        background-color: #ffffff;
    }

    .product-bar {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        align-items: center;
        height: 4rem;
    }

    .product-count-down {
        background-color: #3cc51f;
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: center;
        padding: 0.1rem 0;
        color: #ffffff;
    }

    .vant-count-down {
        color: #ffffff;
    }

    .product-convenient-wrapper {
        width: 45%;
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: space-around;
    }

    .product-convenient-item {
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        padding-top: 0.1rem;
    }

    .product-convenient-item img {
        width: 1.4rem;
    }

    .product-convenient-item p {
        text-align: center;
        font-size: 0.8rem;
        margin-top: 0.2rem;
        color: #7c7c7c;
    }

    .product-button-wrapper {
        display: flex;
        flex-flow: row;
        align-items: center;
        width: 60%;
    }

    .product-button-large {
        background-color: #F66262;
        color: #ffffff;
        text-align: center;
        font-size: 1rem;
        height: 4rem;
        line-height: 4rem;
        width: 100%;
    }

    .product-button-remin {
        border-radius: 10rem;
        background-color: #3cc51f;
        color: #ffffff;
        text-align: center;
        padding: 0.5rem 3.5rem;
        font-size: 1rem;
    }
</style>
