// 版本稳定以后，购物车需要优化成对象，修改存储

// import { getCookie, setCookie } from './cookie'
//
// /**
//  * 立即购买cookie设置
//  * @param businessId 当前购买的商户id
//  * @param productId 当前购买的商品id
//  * @param number 当前购买的数量
//  */
// export function appendNowBuy (businessId, productId, number) {
//   const cartObj = {}
//   const businessObj = {}
//   businessObj[productId] = number
//   cartObj[businessId] = businessObj
//   console.log(cartObj)
//   setCookie('newArr', JSON.stringify(cartObj))
// }
//
// export function getNowBuyProduct () {
//   let cart = getCookie('newArr')
//   cart = JSON.parse(cart)
//   return cart
// }

import { getLocalStorage, setLocalStorage } from './localStorage'

// 加入购买队列
export function appendShopCart (businessId, productId, number, title, price, orgPrice, specs, productImg, buyLimit) {
  const cartObj = getShopCart()
  let businessObj = {}
  const productObj = {}
  if (cartObj[businessId]) { // 已经存在这个商户
    businessObj = cartObj[businessId]
  } else { // 不存在
    businessObj = {}
  }
  productObj.number = number
  productObj.title = title
  productObj.price = price
  productObj.orgPrice = orgPrice
  productObj.tick = false
  productObj.specs = specs
  productObj.img = productImg
  productObj.buylimit = buyLimit
  businessObj[productId] = productObj
  cartObj[businessId] = businessObj
  setLocalStorage('shopCart', JSON.stringify(cartObj))
}

// 获取购物车内容
export function getShopCart () {
  let cart = getLocalStorage('shopCart')
  if (!cart) {
    cart = '{}'
  }
  cart = JSON.parse(cart)
  return cart
}

// 删除购物车某个商品
export function removeShopCart (businessId, productId) {
  const cartObj = getShopCart()
  const businessObj = cartObj[businessId]
  // productId = parseInt(productId)
  delete businessObj[productId]
  // businessObj.productId = undefined
  cartObj[businessId] = businessObj
  // 看一下这个businessId底下还有没有key
  if (Object.keys(cartObj[businessId]).length === 0) {
    delete cartObj[businessId]
  }
  updateShopCart(cartObj)
}

// 更新购物车
export function updateShopCart (dataArray) {
  setLocalStorage('shopCart', JSON.stringify(dataArray))
}

// 立即股买 nowBuy表示下单 shopCart只表示购物车内的内容
export function buyNow (businessId, productId, number) {
  const cartObj = {}
  const businessObj = {}
  businessObj[productId] = number
  cartObj[businessId] = businessObj
  console.log(cartObj)
  setLocalStorage('nowBuy', JSON.stringify(cartObj))
}

// 购物车结算，遍历出已经选择的商品，同时删除转移到nowBuy
export function cartPay () {
  const cartObj = getShopCart() // 当前购物车数据
  const lastCartObj = cartObj // 购买的商品要从购物车删除，操作这个数据
  const nowBuy = {}
  for (var i1 in cartObj) {
    const currentBusinessId = i1
    const currentBusiness = {}
    let hasBuy = 0
    for (var i2 in cartObj[i1]) {
      console.log('businessid :', i1, 'product:', i2, cartObj[i1])
      if (cartObj[i1][i2].tick === true) {
        currentBusiness[i2] = cartObj[i1][i2].number
        hasBuy = 1
        delete lastCartObj[i1][i2] // 如果当前商品被购买，那么删除这个商品
      }
    }
    if (hasBuy === 1) {
      nowBuy[currentBusinessId] = currentBusiness
    }
    // 判断当前商户是否为空，为空把当前商户也直接删除
    if (Object.keys(lastCartObj[i1]).length === 0) {
      delete lastCartObj[i1]
    }
  }
  setLocalStorage('nowBuy', JSON.stringify(nowBuy))
  updateShopCart(lastCartObj)
}

// 下单商品
export function getNowBuyProduct () {
  let cart = getLocalStorage('nowBuy')
  if (!cart) {
    cart = '{}'
  }
  cart = JSON.parse(cart)
  return cart
}
